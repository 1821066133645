import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box, Container, Grid } from "@mui/material";
import { useLocation } from "react-router";
import SupportButton from "../../components/SupportButton";
import LeftSideBar from "./NavBar/LeftSideBar";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "relative",
  // background: "#f3f5f7",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  position: "relative",
  minHeight: "calc(100vh - 54px)",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  marginTop: "54px",
}));

const ContentContainer = styled(Box)({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const Content = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
  position: "relative",
}));

export default function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Root>
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isDashboard={true}
        />
        <Container sx={{ pl: { xs: "0px", sm: "16px" } }}>
          <Box style={{ paddingTop: "120px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                {/* <NavBar
                  onMobileClose={() => setMobileNavOpen(false)}
                  openMobile={isMobileNavOpen}
                /> */}
                <LeftSideBar
                  onMobileClose={() => setMobileNavOpen(false)}
                  openMobile={isMobileNavOpen}
                />
              </Grid>
              <Grid item md={9} sm={12}>
                <Content>{children}</Content>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <SupportButton />
      </Root>
    </React.Fragment>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

import React, { Fragment, useContext, useState } from "react";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Drawer, Box, IconButton, Typography, Grid } from "@mui/material";
import { AuthContext } from "../context/auth";
import { useNavigate } from "react-router";
import { colors } from "../assets/colors";
import SupportModal from "./modals/SupportModal";
import PerfectScrollbar from "react-perfect-scrollbar";
import ThemeContext from "../context/themeContext";
const FloatingButton = styled("div")({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 1000, // Ensure it's above other elements
});

export default function SupportButton() {
  const themeSettings = useContext(ThemeContext);
  const navigate = useNavigate();
  const { userLoggedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <Fragment>
      <FloatingButton>
        <Box
          display="flex"
          alignItems={"center"}
          sx={{
            borderRadius: "20px",
            background: "#432ae1",
            padding: "0px 10px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (userLoggedIn) {
              navigate("/support/raise-tickets");
            } else {
              setOpen(true);
            }
          }}
        >
          <IconButton>
            <SupportAgentIcon sx={{ color: colors.white }} />
          </IconButton>
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{ color: colors.white }}
          >
            Need Help?
          </Typography>
        </Box>
      </FloatingButton>
      {/* {open && <SupportModal open={open} close={() => setOpen(false)} />} */}
      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{
          sx: {
            width: 280,
            // background:theme colors.bgDark,
            backgroundColor:
              themeSettings.settings.theme === "DARK" ? colors.bgDark : "#fff",
          },
        }}
      >
        <PerfectScrollbar component="div">
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <SupportModal close={handleToggle} />
              {/* font family */}
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Drawer>
    </Fragment>
  );
}

import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Container,
  Drawer,
  Hidden,
  List,
  ListSubheader,
} from "@mui/material";
import NavItem from "./NavItem";
import { styled } from "@mui/system";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import { matchPath, useLocation } from "react-router";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { RiUserLine } from "react-icons/ri";
import { CiLock } from "react-icons/ci";
import { IoNotificationsOutline } from "react-icons/io5";
import { BsCurrencyDollar } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { RiStore3Line } from "react-icons/ri";
import CommonHeading from "../../../components/heading/CommonHeading";
import ExtraSmallHeading from "../../../components/heading/ExtraSmallHeading";
import { AuthContext } from "../../../context/auth";
const MobileDrawer = styled(Drawer)(({ theme }) => ({
  width: 272,
  "& .MuiPaper-root": {
    borderRadius: "5px",
  },
}));

const DesktopDrawer = styled(Drawer)(({ theme }) => ({
  top: "95px",
  width: "200px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,

  "& .MuiPaper-root": {
    borderRadius: "0px",
    top: 72,
    width: "300px",
  },
}));
const LogoutButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "100px",
  width: "100%",
}));

const SideMenuBox = styled(Box)(({ theme }) => ({
  width: "300px",
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.secondary.main,
  "& .MuiCollapse-wrapperInner": {
    marginLeft: "45px",
  },
}));

function renderNavItems({ items, pathname, depth = 0, onMobileClose }) {
  return (
    <List disablePadding onClick={onMobileClose}>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, onMobileClose }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, onMobileClose }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        onMobileClose={onMobileClose}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        onMobileClose={onMobileClose}
      />
    );
  }

  return acc;
}

const sections = [
  {
    items: [
      {
        title: "My Profile",
        icon: <RiUserLine />,
        href: "/user-profile",
      },
      {
        title: "Security",
        icon: <CiLock />,
        href: "/security",
      },
      {
        title: "Notification Preferences",
        icon: <IoNotificationsOutline />,
        href: "/notification",
      },
      {
        title: "Currency Preferences",
        icon: <BsCurrencyDollar />,
        href: "/currency",
      },

      {
        title: "KYC Verification",
        icon: <IoDocumentTextOutline />,
        href: "/user-kyc-info",
      },
      {
        title: "Payment Option",
        icon: <MdOutlinePayment />,
        href: "/payment",
      },
      {
        title: "Api Managment",
        icon: <RiStore3Line />,
        href: "/api-management",
      },
    ],
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const route = useLocation();
  const auth = useContext(AuthContext);
  const { profile } = auth.userData;
  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? colors.bgDark : "#1d1d1d0a",
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
    borderRadius: "10px",
  };
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // justifyContent="center"
      sx={themeColors}
      position="fixed"
    >
      <Box pt={2} pb={2}>
        <Box flexDirection="column" className="displayCenter">
          <Box
            className="displayCenter"
            style={{
              height: "125px",
              width: "125px",
              borderRadius: "120px",
              background: "#D8D8D8",
              overflow: "hidden",
              marginBottom: "10px",
            }}
          >
            <img
              width="100%"
              src={
                profile.profile_image
                  ? profile.profile_image
                  : "/images/banner/placeholder.png"
              }
              alt="profile"
            />
          </Box>
          <CommonHeading
            heading={`${
              profile?.first_name
                ? `${profile?.first_name} ${profile?.last_name}`
                : "--"
            }`}
            fontSize="23px"
          />
          <ExtraSmallHeading
            heading={profile?.user_name ? profile?.user_name : "--"}
          />
        </Box>
        <SideMenuBox>
          {sections?.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                img: section.img,
                items: section.items,
                pathname: route.pathname,
                state: section.tabview,
                onMobileClose: onMobileClose,
              })}
            </List>
          ))}
        </SideMenuBox>
      </Box>

      {/* Rest of your code */}
    </Box>
  );

  const logoutButton = (
    <List sx={{ width: "100%" }}>
      <NavItem
        depth={0}
        href={"/logout"}
        icon={<ExitToAppIcon color="secondary" />}
        info={""}
        key={"logout"}
        title={"Logout"}
      />
    </List>
  );

  return (
    <>
      <Hidden lgUp>
        {openMobile && (
          <MobileDrawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            <Box
              p={2}
              height="100%"
              className="displaySpacebetween"
              flexDirection="column"
              sx={{
                position: "fixed",
                left: "136px",
              }}
            >
              {content}
              {/* {logoutButton} */}
            </Box>
          </MobileDrawer>
        )}
      </Hidden>
      <Hidden mdDown>
        <Card>
          {content}
          {/* <LogoutButton>{logoutButton}</LogoutButton> */}
        </Card>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import React from "react";
import FlexButton from "../FlexButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmationModal({
  close,
  title,
  open,
  description,
  onClick,
}) {
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle position="relative">
        <Typography variant="h4" textAlign="center">
          {title}
        </Typography>
        <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" textAlign="center">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box className="displayCenter" width="100%">
          <FlexButton
            title="Close"
            color="secondary"
            type=""
            sx={{ marginRight: "10px" }}
            onClick={close}
          />
          <FlexButton
            title="Confirm"
            color="primary"
            type=""
            onClick={onClick}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}

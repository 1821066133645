import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  Card,
  DialogActions,
  Paper,
} from "@mui/material";
import { React, forwardRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Fragment } from "react";
import moment from "moment";
import NoDataFound from "../NoDataFound";
import { colors } from "../../assets/colors";
import { apiRouterCall } from "../../api/services";
import ScreenLoader from "../screenLoader";

export default function NotificationModal({ close, open, data }) {
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const dates = new Set();
  const renderDate = (chat, dateNum) => {
    const timestampDate = moment(chat.createdAt).format("DD MMM YYYY");
    // Add to Set so it does not render again
    dates.add(dateNum);
    const currentDate = moment().format("L");
    return (
      <Typography variant="body2" mt={1} fontWeight={600}>
        {moment(currentDate).isSame(moment(timestampDate).format("L"))
          ? "Today"
          : moment(currentDate)
              .subtract(1, "d")
              .isSame(moment(timestampDate).format("L"))
          ? "Yesterday"
          : timestampDate}
      </Typography>
    );
  };

  //function to mark all notification as read
  const markAllNotificationAsReadHandler = async () => {
    try {
      close();
      setIsProcessing(true);
      await apiRouterCall({
        method: "GET",
        endPoint: "readNotification",
      });
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth={"xs"}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            top: 20,
            right: 20,
            bottom: 0,
            margin: 0,
            padding: 0,
            borderRadius: 0,
            height: "100%",
          },
        }}
      >
        <DialogTitle
          style={{ position: "relative", borderBottom: "1px white solid" }}
        >
          <Typography variant="h4" textAlign="left">
            Notifications
          </Typography>
          <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
            <Tooltip title="Close">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            {data &&
              data?.map((item, i) => {
                const dateNum = moment(item.createdAt).format("ddMMyyyy");
                return (
                  <Fragment key={`notification-${i}`}>
                    {dates.has(dateNum) ? null : renderDate(item, dateNum)}
                    <NotificationCard item={item} />
                  </Fragment>
                );
              })}
          </Box>
          {data && data.length === 0 && <NoDataFound text={"No data found!"} />}
        </DialogContent>
        {data && data.length !== 0 && (
          <DialogActions sx={{ justifyContent: "center" }}>
            <Box className="displayCenter">
              <Typography
                variant="body2"
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: colors.btnGold,
                  },
                }}
                onClick={markAllNotificationAsReadHandler}
              >
                Mark all as read
              </Typography>
            </Box>
          </DialogActions>
        )}
      </Dialog>
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
    </Fragment>
  );
}

const NotifyData = ({ item }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  //function to mark notifiaction as read
  const markAsReadNotification = async () => {
    try {
      setIsProcessing(true);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "notification",
        id: item?._id,
      });
      if (response.data.responseCode === 200) {
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  return (
    <Fragment>
      <Typography variant="body2" fontWeight={600}>
        {item && item?.heading}
      </Typography>
      <Typography variant="body2" mt={0.5} mb={1}>
        {item && item?.msg}
      </Typography>
      <Typography variant="body1">
        {moment(item?.createdAt).fromNow()}
      </Typography>
      {item?.seen_status === 0 && (
        <Box textAlign={"center"}>
          <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: colors.btnGold,
              },
            }}
            onClick={markAsReadNotification}
          >
            Mark as read
          </Typography>
        </Box>
      )}
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
    </Fragment>
  );
};

const NotificationCard = ({ item }) => {
  return (
    <Fragment>
      {item?.seen_status === 0 && (
        <Card sx={{ padding: "10px", margin: "10px 0px", cursor: "pointer" }}>
          <NotifyData item={item} />
        </Card>
      )}
      {item?.seen_status === 1 && (
        <Paper sx={{ padding: "10px", margin: "10px 0px", cursor: "pointer" }}>
          <NotifyData item={item} />
        </Paper>
      )}
    </Fragment>
  );
};

export const colors = {
  darkPrimary: "#080808",
  darkSecondary: "#080808",
  bgDark: "#121318",
  white: "#fff",
  black: "#000",
  darkGrey: "#121318",
  gold: "#5367FF",
  btnGold: "#5367FF",
  red: "#FB3E3E",
  green: "#01bc8d",
  mainColor: "#0083F8",
  textColor: "#9295A6",
  dividerColor: "#CCCED9",
};

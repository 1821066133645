import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import ThemeContext from "../../context/themeContext";
const DialogMain = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paperScrollPaper": {
    background: "transparent !important",
  },
  "& .MuiPaper-elevation24": {
    boxShadow: "none !important",
  },
}));
export default function ScreenLoader({ open, close }) {
  const themeSettings = useContext(ThemeContext);
  const isDark = themeSettings.settings.theme === "DARK" ? true : false;
  return (
    <DialogMain
      open={open}
      onClose={() => {
        if (!open) {
          close();
        }
      }}
      style={{
        background: "transparent !important",
        backdropFilter: "blur(3px)",
        width: "100%",
        height: "100%",
      }}
      fullWidth
    >
      <DialogContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <img
            src={
              isDark
                ? "/assets/logo/logo_dark.svg"
                : "/assets/logo/logo_light.png"
            }
            alt="loader"
            width="100px"
          />
          <Typography
            variant="body1"
            style={{ color: "#fff", marginTop: "10px" }}
          >
            Please wait...
          </Typography>
        </Box>
      </DialogContent>
    </DialogMain>
  );
}

import toast from "react-hot-toast";
import { apiRouterCall } from "../api/services";
import moment from "moment";

export const getRandomAvtar = (value) => {
  return `https://api.multiavatar.com/${value}.png`;
};

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddressDoc(add) {
  const sortAdd = `${add?.slice(0, 3)}****${add?.slice(add.length - 3)}`;
  return sortAdd;
}

//function to upload KYC documents
export const uploadDocHandler = async (file, id) => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("title", id);
    formData.append("user_id", window.sessionStorage.getItem("user_id"));
    formData.append("data", id);
    const response = await apiRouterCall({
      endPoint: "uploadKYC",
      method: "POST",
      data: formData,
    });
    const { responseMessage } = response.data;
    if (response.data.responseCode === 200) {
      toast.success(responseMessage);

      return true;
    } else {
      toast.error(responseMessage);

      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
  }
};

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export function obscureEmail(value) {
  if (!value) {
    return "";
  }
  const split = value && value.split("@");
  const domain = value && split[1];
  const hidemail = split && split[0] && split[0].slice(0, 2);
  return `${hidemail}****@${domain}`;
}

export function formatPhoneNumberWithCountryCode(phoneNumber) {
  // Extract the country code
  const countryCode = phoneNumber.substring(0, 2);
  // Format the phone number
  const formattedPhoneNumber = `+${countryCode} xxxx - xxxx`;

  return formattedPhoneNumber;
}

export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase();
  if (decimal?.includes("e+")) {
    const exponentialSplitted = decimal?.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal?.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal?.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const exponentialToDecimalWithoutComma = (x) => {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x?.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x?.toString().substring(2);
    }
  } else {
    let e = parseInt(x?.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0);

  amt = amt?.replace(",", "");
  let arr = amt?.toString().split(".");

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString()
      ).toString();
    } else {
      return numberWithCommas(amt).toString();
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString();
    }
    return "0";
  }
};
function intlFormat(num) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}
export function makeFriendly(num) {
  if (num >= 1000000) return intlFormat(num / 1000000) + "M";
  if (num >= 1000) return intlFormat(num / 1000) + "k";
  return intlFormat(num);
}

const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 6);

  let arr = str.split(".");

  let numbers = arr[0];
  let decimalNum = "";
  if (arr.length > 1) {
    decimalNum = arr[1];
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalNum}`;
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString();
  if (str?.includes(".")) {
    str = str.slice(0, str.indexOf(".") + (decimals + 1));
  }
  return str;
};

export const convertINRDecimals = (value) => {
  let formatter = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
  return formatter.format(value);
};

export const convertUSDDecimals = (value) => {
  let formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USd",
  });
  return formatter.format(value);
};

export function numberCompactFormat(value) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
}

export function formatAsPercentage(num) {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(num / 100);
}
export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export function graphDataMaker(value) {
  return value && value.map((x) => x && x[0]);
}

export const findPlanStatus = (startDate, endDate) => {
  if (startDate && endDate) {
    if (moment(endDate).unix() < moment().unix()) {
      return "Finished";
    } else if (
      moment().unix() < moment(endDate).unix() &&
      moment().unix() > moment(startDate).unix()
    ) {
      return "Live";
    } else {
      return "Upcoming";
    }
  }
};

export function counterTimer(endTime) {
  const now = moment();

  const targetDate = moment.unix(endTime);
  const diff = targetDate.diff(now);
  const duration = moment.duration(diff);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return { days, hours, minutes, seconds, diff };
}
export const calculatePercentage = (value, percentage) => {
  const calculatedResult = (value * percentage) / 100;
  return calculatedResult;
};
export const getCardType = (cardNumber) => {
  // Remove spaces and non-numeric characters from the card number
  const cleanedCardNumber = cardNumber.replace(/\D/g, "");

  // Define patterns for different card types
  const patterns = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    amex: /^3[47]/,
    discover: /^6(?:011|5[0-9]{2})/,
    diners: /^3(?:0[0-5]|[68][0-9])/,
    jcb: /^(?:2131|1800|35)/,
  };

  // Check the card type using the defined patterns
  for (const [type, pattern] of Object.entries(patterns)) {
    if (pattern.test(cleanedCardNumber)) {
      return type.toUpperCase();
    }
  }

  // If no type matches, return unknown
  return "Unknown";
};
export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Toolbar,
  AppBar,
  Button,
  Box,
  Menu,
  MenuItem,
  Divider,
  Container,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../../../components/Logo";
import { useLocation, useNavigate } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import NavBarRight from "../../HomeLayout/NavBarRight";
import RightOptions from "./RightOptions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import { AuthContext } from "../../../context/auth";
const TopBar = ({ onMobileNavOpen, isDashboard }) => {
  const navigate = useNavigate();

  const navItems = [
    {
      name: "Market",
      href: "/market-overview",
      isMenu: false,
    },

    {
      name: "Watchlist",
      href: "/watchlist",
      isMenu: false,
    },
    {
      name: "Portfolio",
      href: "/portfolio",
      isMenu: false,
    },
    // {
    //   name: "Learn",
    //   href: "/learn",
    //   isMenu: false,
    // },
  ];
  const [openDrawer, setOpenDrawer] = useState(false);

  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? "#080808" : colors.white,
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
    borderRadius: "0px",
  };
  const location = useLocation();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar component="nav" sx={themeColors}>
        <Toolbar style={{ minHeight: "87px" }} maxWidth="lg">
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                {isDashboard && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onMobileNavOpen}
                    sx={{ mr: 2, display: { sm: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}

                <Logo
                  style={{
                    // width: "30%",
                    // minWidth: "100px",
                    // maxWidth: "150px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    backgroundColor:
                      themeSettings.settings.theme === "DARK"
                        ? "#b9b3b3"
                        : "#fff",
                    margin: "0px 0px 0px 50px",
                  }}
                />
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      lg: "flex",
                      justifyContent: "center",
                    },
                    marginLeft: "80px",
                    width: "100%",
                  }}
                >
                  {navItems &&
                    navItems.map((item, i) => {
                      return (
                        <DrawerTopButton
                          item={item}
                          key={`navBtn${i}`}
                          navigate={navigate}
                          pathname={location && location.pathname}
                        />
                      );
                    })}
                </Box>
              </Box>

              <RightOptions
                themeSettings={themeSettings}
                sx={{
                  display: {
                    lg: "flex",
                    sm: "none",
                    md: "none",
                    xs: "none",
                    justifyContent: "end",
                  },
                  alignItems: "center",
                }}
              />

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ display: { sm: "block", lg: "none" } }}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <NavBarRight
                onMobileClose={() => setOpenDrawer(false)}
                openMobile={openDrawer}
                themeSettings={themeSettings}
              />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const DrawerTopButton = ({ navigate, item, pathname }) => {
  const themeSettings = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {item?.isLogin ? (
        <Button
          // color="secondary"
          style={{ color: pathname === item.href ? "#000" : "#fff" }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
            color: pathname === item.href ? "#000" : "#fff",
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      ) : (
        <Button
          // color="secondary"
          style={
            pathname === item.href
              ? {
                  borderBottom: "1px solid currentColor",
                  borderRadius: "inherit",
                  color: colors.gold,
                }
              : {
                  color:
                    themeSettings.settings.theme === "DARK" ? "#fff" : "#000",
                }
          }
          // style={{ color: pathname === item.href ? colors.gold : "#fff",  }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      )}

      {open && (
        <>
          {item.isMenu && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {item &&
                item.menuItems &&
                item.menuItems.map((x, i) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(x.href);
                      }}
                      key={`topMenuSub${i}`}
                    >
                      <img src={x.icon} alt="" />
                      &nbsp;&nbsp;{x.title}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
        </>
      )}
    </React.Fragment>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.progress-container {
  width: 200px;
  height: 200px;
  position: relative;
}

.progress-container .progress-bar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd; /* Default background color */
}

.progress-container .progress-bar .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 100px, 200px, 0);
  background-color: #4CAF50; /* Progress color */
}

.progress-container .progress-bar .progress::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: transparent;
  border-radius: 0 100% 100% 0 / 50%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB,EAAE,6BAA6B;AACvD;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,yBAAyB,EAAE,mBAAmB;AAChD;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,6BAA6B;EAC7B,kCAAkC;AACpC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.progress-container {\n  width: 200px;\n  height: 200px;\n  position: relative;\n}\n\n.progress-container .progress-bar {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #ddd; /* Default background color */\n}\n\n.progress-container .progress-bar .progress {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  clip: rect(0, 100px, 200px, 0);\n  background-color: #4CAF50; /* Progress color */\n}\n\n.progress-container .progress-bar .progress::after {\n  content: \"\";\n  position: absolute;\n  width: 50%;\n  height: 100%;\n  background-color: transparent;\n  border-radius: 0 100% 100% 0 / 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItemText,
  IconButton,
  ListItem,
  Container,
  Card,
} from "@mui/material";
import Logo from "../../components/Logo";
import { colors } from "../../assets/colors";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RedditIcon from "@mui/icons-material/Reddit";
import { RxDiscordLogo } from "react-icons/rx";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.secondary.main,
  height: "100%",
  width: "100%",
  padding: "50px 30px",
  "& svg": {
    marginRight: "20px",
    color: colors.textColor,
  },
}));

// data list footer right container
const footerOptions = [
  {
    title: "Exchange",
    isIcons: false,
    options: [
      { name: "Exchange Home", href: "/about" },
      { name: "Margin Trading", href: "/resources/all" },
      { name: "Derivatives Trading", href: "" },
      { name: "Supercharger", href: "" },
    ],
  },
  {
    title: "Support",
    isIcons: false,
    options: [
      { name: "Request form", href: "" },
      { name: "Contact Support", href: "" },
      { name: "FAQ", href: "" },
      { name: "Security", href: "" },
    ],
  },
  {
    title: "Resources",
    isIcons: false,
    options: [
      { name: "Downloads", href: "" },
      { name: "Desktop Aplication", href: "" },
      { name: "Buy Crypto", href: "" },
      { name: "Referral", href: "" },
      { name: "Listing Tranding", href: "" },
    ],
  },
  {
    title: "Learn",
    isIcons: false,
    options: [
      { name: "What’s Trending", href: "" },
      { name: "Product News", href: "" },
      { name: "Events", href: "" },
      { name: "University", href: "" },
      { name: "Research", href: "" },
      { name: "Market Update", href: "" },
    ],
  },
  {
    title: "Company",
    isIcons: false,
    options: [
      { name: "About us", href: "/support/raise-tickets" },
      { name: "Careers", href: "/support/raise-tickets" },
      { name: "News", href: "" },
      { name: "Security", href: "" },
      { name: "Community", href: "" },
      { name: "Announcements", href: "" },
    ],
  },
  // {
  //   title: "Community",
  //   isIcons: true,
  //   options: [
  //     {
  //       id: "twitter",
  //       icon: <TwitterIcon />,
  //       href: "https://twitter.com/TaralityCoin",
  //     },
  //     { id: "telegram", icon: <TelegramIcon />, href: "https://t.me/Tarality" },
  //     {
  //       id: "linkedIn",
  //       icon: <LinkedInIcon />,
  //       href: "https://www.linkedin.com/company/tarality/",
  //     },
  //     {
  //       id: "instagram",
  //       icon: <InstagramIcon />,
  //       href: "https://www.instagram.com/taralitycoin/",
  //     },
  //     {
  //       id: "facebook",
  //       icon: <FacebookIcon />,
  //       href: "https://www.facebook.com/TaralityCoin",
  //     },
  //     {
  //       id: "youtube",
  //       icon: <YouTubeIcon />,
  //       href: "https://www.youtube.com/@taralitycoin",
  //     },
  //     {
  //       id: "reddit",
  //       icon: <RedditIcon />,
  //       href: "https://www.reddit.com/user/taralityecosystem",
  //     },
  //     {
  //       id: "discord",
  //       icon: <RxDiscordLogo />,
  //       href: "https://discord.gg/8chawtthnj",
  //     },
  //   ],
  // },
];

//main export const which is rendered in the home layout
export default function Footer({ themeSettings }) {
  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={3} style={{ paddingBottom: "30px" }}>
          <Grid item lg={4} sm={5} md={5} xs={12}>
            <Logo
            // style={{ width: "60%", minWidth: "100px", maxWidth: "250px" }}
            />
            <Box sx={{ width: "80%" }}>
              <Typography variant="body1" color={colors.textColor}>
                Crypto currency investment is risky and unregulated in India. We
                only provide services, Tarality will not be responsible for any
                loss or profit. E&OE
              </Typography>
              <Box mt={6}>
                <FacebookIcon />
                <InstagramIcon />
                <YouTubeIcon />
                <TwitterIcon />
                <LinkedInIcon />
              </Box>
            </Box>

            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item lg={8} sm={7} md={7} xs={12}>
            <FooterRightContent themeSettings={themeSettings} />
          </Grid>
        </Grid>
        <Divider
          orientation="horizantal"
          flexItem
          sx={{
            backgroundColor:
              themeSettings.settings.theme === "DARK"
                ? colors.dividerColor
                : "#fff",
            margin: "0px 4px",
            opacity: themeSettings.settings.theme === "DARK" ? "15%" : "100%",
          }}
        />
        <FooterCopyrightBar themeSettings={themeSettings} />
      </Container>
    </FooterContainer>
  );
}

const FooterRightContent = ({ themeSettings }) => {
  const listHeaderStyles = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? colors.bgDark : colors.white,
    fontSize: "18px",
    paddingLeft: "0px",
    paddingBottom: "15px",
    "& span": {
      color: `${colors.textColor} !important`,
    },
  };

  const navigate = useNavigate();

  return (
    <Grid container>
      {/* {footerOptions &&
        footerOptions.map((item, i) => {
          return (
            <Grid item lg={12} xs={12} md={12} sm={6} key={`footerOptions${i}`}>
              <Box display="flex">
                <List
                  sx={{ width: "100%", maxWidth: 360 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      sx={listHeaderStyles}
                    >
                      {item.title}
                    </ListSubheader>
                  }
                >
                  {!item.isIcons ? (
                    item.options.map(({ name, href }) => {
                      return (
                        <ListItem
                          key={name}
                          sx={{
                            padding: "0px 16px 0px 0px",
                            cursor: "pointer",
                            "&:hover": {
                              color: colors.gold,
                            },
                          }}
                        >
                          <ListItemText
                            primary={name}
                            onClick={() => navigate(href)}
                          />
                        </ListItem>
                      );
                    })
                  ) : (
                    <Box>
                      {item.options.map(({ icon, href, id }) => {
                        return (
                          <IconButton
                            key={id}
                            onClick={() => window.open(href)}
                          >
                            {icon}
                          </IconButton>
                        );
                      })}
                    </Box>
                  )}
                </List>
              </Box>
            </Grid>
          );
        })} */}
      {footerOptions &&
        footerOptions.map((item, i) => {
          return (
            <Box
              display="flex"
              style={{ width: "100%", maxWidth: "150px", flexWrap: "wrap" }}
            >
              <List
                sx={{ width: "100%", maxWidth: 360, paddingBottom: "15px" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={listHeaderStyles}
                  >
                    {item.title}
                  </ListSubheader>
                }
              >
                {!item.isIcons ? (
                  item.options.map(({ name, href }) => {
                    return (
                      <ListItem
                        key={name}
                        sx={{
                          padding: "0px 16px 0px 0px",
                          cursor: "pointer",
                          color: colors.textColor,
                          "&:hover": {
                            color: colors.gold,
                          },
                        }}
                      >
                        <ListItemText
                          primary={name}
                          onClick={() => navigate(href)}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Box>
                    {item.options.map(({ icon, href, id }) => {
                      return (
                        <IconButton key={id} onClick={() => window.open(href)}>
                          {icon}
                        </IconButton>
                      );
                    })}
                  </Box>
                )}
              </List>
            </Box>
          );
        })}
    </Grid>
  );
};

const FooterCopyrightBar = ({ themeSettings }) => {
  const textClass = {
    textTransform: "none",
    margin: "0px 10px",
    cursor: "pointer",
    "&:hover": {
      color: colors.gold,
    },
  };
  const textClass1 = {
    textTransform: "none",
    margin: "0px 5px",
    cursor: "pointer",
  };
  const navigate = useNavigate();
  const deviceList = [
    {
      title: "Download PC-Client Windows",
      logo: "/images/footer_device_icon/windows.png",
    },
    {
      title: "Download for the MacOS",
      logo: "/images/footer_device_icon/apple.png",
    },
    {
      title: "Download on the App Store",
      logo: "/images/footer_device_icon/ios.png",
    },
    {
      title: "Download PC-Client Windows",
      logo: "/images/footer_device_icon/playstore.png",
    },
  ];
  const iconCard = {
    backgroundColor: themeSettings.settings.theme === "DARK" ? "#000" : "#fff",
    padding: "5px",
    margin: "0px 10px 0px 0px",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      background: colors.gold,
    },
    "@media(max-width:768px)": {
      marginTop: "5px",
    },
  };
  return (
    <Box
      sx={{
        marginTop: "50px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          lg={6}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              alignItems: { xs: "center", sm: "center" },
            }}
          >
            <Typography variant="body1" color={colors.textColor}>
              {`© ${moment().format("YYYY")} All rights reserved.`}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor:
                  themeSettings.settings.theme === "DARK" ? "#fff" : "#fff",
                margin: "0px 5px 0px 50px",
              }}
            />
            <Typography
              variant="body1"
              sx={textClass}
              color={colors.textColor}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor:
                  themeSettings.settings.theme === "DARK" ? "#fff" : "#fff",
                margin: "0px 5px 0px 20px",
              }}
            />
            <Typography
              color={colors.textColor}
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/terms-conditions")}
            >
              Terms
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor:
                  themeSettings.settings.theme === "DARK" ? "#fff" : "#fff",
                margin: "0px 5px 0px 20px",
              }}
            />
            <Typography
              color={colors.textColor}
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/terms-conditions")}
            >
              Sitemap
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              // alignItems: { xs: "center", sm: "center" },
              // justifyContent: { xs: "center", sm: "start" },
              marginTop: { xs: "25px", sm: "25px", md: "0px" },
            }}
          >
            {deviceList.map((item) => {
              return (
                <Box className="displayCenter " sx={iconCard}>
                  <img src={item.logo} alt="logo" />

                  <Typography
                    variant="body1"
                    sx={textClass1}
                    onClick={() => navigate("")}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

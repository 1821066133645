import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Badge,
  Typography,
  Button,
  Avatar,
  Paper,
} from "@mui/material";
import { React, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ThemeSwitchButton from "../../../components/themeSwitchButton";
import NotificationModal from "../../../components/modals/NotificationModal";
import { AuthContext } from "../../../context/auth";
import { FlexLayerContext } from "../../../context/flexLayer";
import FlexButton from "../../../components/FlexButton";
import { colors } from "../../../assets/colors";
const commonCardBtn = {
  borderRadius: "5px",
  border: "1px solid var(--Primary-CTA-700, #5367FF)",
  padding: "14px",
  cursor: "pointer",
};
export default function RightOptions({ themeSettings, sx }) {
  const { userLoggedIn, userData } = useContext(AuthContext);

  const { notificationData } = useContext(FlexLayerContext);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const notificationModal = useMemo(() => {
    return (
      <NotificationModal
        open={isActive}
        close={() => setIsActive(false)}
        data={notificationData?.data}
      />
    );
  }, [isActive]);

  return (
    <Box sx={sx} width="100%">
      <Box className="displayCenter">
        <Typography>English</Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor:
              themeSettings.settings.theme === "DARK" ? "#b9b3b3" : "#fff",
            margin: "0px 8px",
          }}
        />
        <Box className="displayCenter">
          <Typography style={{ marginRight: "5px" }}>USD</Typography>
          <KeyboardArrowDownIcon />
        </Box>
        {/* <Button
          color="secondary"
          id="basic-button"
          // aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
          // onClick={(e) => {
          //   if (item.isMenu) {
          //     handleClick(e);
          //   } else {
          //     navigate(item.href);
          //   }
          // }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
          }}
          // endIcon={
          //   item.isMenu && (
          //     <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
          //   )
          // }
          endIcon={<KeyboardArrowUpIcon />}
        >
          USD
        </Button> */}
      </Box>

      {userLoggedIn ? (
        <>
          <Paper>
            <Box sx={commonCardBtn} onClick={() => navigate("/trade-history")}>
              <Typography>Trade History</Typography>
            </Box>
          </Paper>
          &nbsp;
          <Paper>
            <Box sx={commonCardBtn} onClick={() => navigate("/wallet")}>
              <Typography>Wallet</Typography>
            </Box>
          </Paper>
          <Tooltip title="Profile">
            <IconButton
              style={{ overflow: "hidden", height: "64px", width: "64px" }}
              onClick={() => navigate("/user-profile")}
            >
              <Avatar
                src={
                  userData && userData.profile && userData.profile.profile_image
                    ? userData.profile.profile_image
                    : "/images/banner/placeholder.png"
                }
              />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <FlexButton
            title="Sign In"
            width="130px"
            onClick={() => navigate(`/login`)}
            sx={{
              padding: { xs: "4px", lg: "10px" },
              color: colors.gold,
              margin: "0px 15px",
              textTransform: "capitalize",
            }}
          />
          <FlexButton
            color="primary"
            title="Register"
            width="130px"
            textcolor="#fff"
            onClick={() => navigate(`/signup`)}
            sx={{ padding: { xs: "4px", lg: "10px" }, margin: "0px 25px" }}
          />
        </>
      )}

      <ThemeSwitchButton />
      {/* <Tooltip title="Wallet">
        <IconButton onClick={() => navigate("/wallet")}>
          <AccountBalanceWalletIcon />
        </IconButton>
      </Tooltip> */}
      {/* {userLoggedIn && (
        <Tooltip title="Notifications">
          <IconButton onClick={() => setIsActive(true)}>
            <Badge
              badgeContent={notificationData && notificationData?.unReadCount}
              color="secondary"
            >
              <NotificationsIcon color="secondary" />
            </Badge>
          </IconButton>
        </Tooltip>
      )} */}

      {/* <Divider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor:
            themeSettings.settings.theme === "DARK" ? "#b9b3b3" : "#fff",
          margin: "0px 4px",
        }}
      /> */}
      {isActive && notificationModal}
    </Box>
  );
}

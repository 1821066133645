import React from "react";
import { useContext } from "react";
import ThemeContext from "../context/themeContext";

export default function Logo(props) {
  const themeSettings = useContext(ThemeContext);
  return (
    <img
      className="Logoimg"
      src={
        themeSettings.settings.theme === "DARK"
          ? "/assets/logo/logo.png"
          : "/assets/logo/logo.png"
      }
      {...props}
      alt="site_logo"
    />
  );
}

//staging server
export const baseURL = "https://stgapi-exchangev2.tarality.io";
export const socket_web_url = "wss://stgwebsocket.tarality.io";
// export const socket_url = "https://socket.tarality.io";
// export const notification_socket_url = "https://stgapi-exchange.tarality.io";

// production server
// export const baseURL = "https://api.tarality.io";
// export const socket_url = "https://prodsocket.tarality.io";
// export const socket_web_url = "wss://websocket.tarality.io";
// export const notification_socket_url = "https://api.tarality.io";
export const orderbookJavaUrl = "https://orderbook.tarality.io";
export const staticContentURL =
  "https://api.tarality.online/api/v2/page/getPagelist/a";

let version = "api"; //v0
let version_v1 = "api/v1"; //v0
let kyc = `${version_v1}/kyc`;
let user = `${version_v1}/user`;
let plan = `${version_v1}/user/plan`;
let cbip = `${version_v1}/cbip`;
let graph = `${version_v1}/graph`;

let bank = `${version_v1}/bank`;
let lockfund = `${version_v1}/lockfund`;
let reward = `${version_v1}/reward`;
let help = `${version_v1}/help`;
let notification = `${version_v1}/notification`;

const apiConfigs = {
  login: `${baseURL}/${user}/loginOtp`,
  userProfile: `${baseURL}/${user}/profile`,
  // userWallet: `${baseURL}/${version}/get-wallets-new`,
  register: `${baseURL}/${user}/register`,
  verifyOTP: `${baseURL}/${user}/verifyOTP`,
  resetPassword: `${baseURL}/${user}/resetPassword`,
  // freezeAccount: `${baseURL}/${version}/freeze-acount`,
  forgotPassword: `${baseURL}/${user}/forgotPassword`,
  resendOtp: `${baseURL}/${user}/resendOtp`,
  verifyLoginOtp: `${baseURL}/${user}/verifyLoginOtp`,
  updateProfile: `${baseURL}/${user}/updateProfile`,
  mobileOtp: `${baseURL}/${user}/mobile_otp`,
  verifyMobileOtp: `${baseURL}/${user}/verify_mobile_otp`,
  uploadImageFile: `${baseURL}/${user}/uploadImageFile`,
  uploadImage: `${baseURL}/${user}/uploadImage`,

  // <<<<<< kyc >>>>>>
  checkStatus: `${baseURL}/${kyc}/check-status`,
  validateAadhar: `${baseURL}/${kyc}/validate-aadhar`,
  verifyAdhar: `${baseURL}/${kyc}/verify-adhar`,
  verifyPan: `${baseURL}/${kyc}/verify-pan`,
  uploadSelfie: `${baseURL}/${kyc}/upload-selfie`,
  submitKyc: `${baseURL}/${kyc}/submit-kyc`,

  // verifyOTPForgot: `${baseURL}/${version}/varifie/forget-password`,
  // changePassword: `${baseURL}/${version}/set-password`,
  // gerReferralCode: `${baseURL}/${version}/user/get-referals`,
  // updateWallet: `${baseURL}/${version}/update-wallet`,
  // //----2FFA----//
  // get2FaQR: `${baseURL}/${version}/set-auth-google`,
  // enable2FA: `${baseURL}/${version}/set-auth-google-otp`,
  // sendMobileOTP: `${baseURL}/${version}/send-mobile-varification-otp`,
  // verifyMobileOTP: `${baseURL}/${version}/varifie/mobile`,
  // //----kyc----//
  // updateKycProfile: `${baseURL}/${kyc}/update-profile`,
  // checkStatus: `${baseURL}/${version_v1}/${kyc}/check-status`,
  // uploadKYC: `${baseURL}/${version_v1}/${kyc}/upload-kyc`,
  // verifyPan: `${baseURL}/${version_v1}/${kyc}/verify-pan`,
  // validateAadhar: `${baseURL}/${version_v1}/${kyc}/validate-aadhar`,
  // verifyAadhar: `${baseURL}/${version_v1}/${kyc}/verify-adhar`,
  // submitKYC: `${baseURL}/${version_v1}/${kyc}/submit-kyc`,
  // uploadSelfie: `${baseURL}/${version_v1}/${kyc}/upload-selfie`,
  // //----crypto deposit/withdraw----//
  // coinList: `${baseURL}/${version}/getallsymbol`,
  // getQrCode: `${baseURL}/${version}/get-blockchain-data`,
  // cryptoWithdrawHistory: `${baseURL}/${version}/withdraw_history`,
  // cryptoDepositHistory: `${baseURL}/${version}/deposit_history`,
  // generateWithdrawOTP: `${baseURL}/${version}/get-withdraw`,
  // verifyMobile: `${baseURL}/${version}/varifie/mobile-Withdraw`,
  // verifyEmail: `${baseURL}/${version}/varifie/email-Withdraw`,
  // balanceDetails: `${baseURL}/${user}/balanceDetails`,
  // //----crypto banking----//
  // activeAssetsList: `${baseURL}/${cbip}/activeAssetsList`,
  // investBalance: `${baseURL}/${cbip}/investBalance`,
  // investHistory: `${baseURL}/${cbip}/investHistory`,
  // withdrawCryptoBankingFund: `${baseURL}/${cbip}/withdrawCryptoBankingFund`,
  // applyLoan: `${baseURL}/${cbip}/applyLoan`,
  // payLoan: `${baseURL}/${cbip}/payLoan`,
  // paymentHistory: `${baseURL}/${cbip}/paymentHistory`,
  // addSpotFund: `${baseURL}/${user}/addSpotFund`,
  // withdrawSpotFund: `${baseURL}/${user}/withdrawSpotFund`,
  // spotHistory: `${baseURL}/${user}/spotHistory`,
  // //----trade----//
  // fastTradeOrder: `${baseURL}/${version}/fast-trade-order`,
  // fastTradeHistory: `${baseURL}/${version}/fast-trade-history`,
  // //----graph----//
  // graphData: `${baseURL}/${graph}/cmc_graph_data`,
  // // ----Taral Earn/ Drop----//
  // getPlanList: `${baseURL}/${plan}/getPlanList`,
  // participate: `${baseURL}/${plan}/participate`,
  // detailsParticipant: `${baseURL}/${plan}/detailsParticipant`,
  // topParticipants: `${baseURL}/${plan}/topParticipants`,
  // userPlanDetails: `${baseURL}/${plan}/userPlanDetails`,
  // listHistory: `${baseURL}/${plan}/listHistory`,
  // planInvestmentHistory: `${baseURL}/${plan}/planInvestmentHistory`,
  // earnDropHistory: `${baseURL}/${plan}/earnDropHistory`,
  // //----order-book----//
  // buyOrder: `${baseURL}/${version}/buy-order1`,
  // sellOrder: `${baseURL}/${version}/sell-order1`,
  // executeOrder: `${baseURL}/${version}/execute-orders`,
  // pendingOrders: `${baseURL}/${version}/pending-order`,
  // completeOrders: `${baseURL}/${version}/complete-order`,
  // cancelOrder: `${baseURL}/${version}/cancle-order`,
  // getpairedCurrency: `${baseURL}/${version}/getpairedCurrency`,
  // //--subscriber--//
  // subscribe: `${baseURL}/${version_v1}/subscriber/createSubscriber`,
  // // --- anouncement---//
  // announcmentList: `${baseURL}/${version_v1}/announcement/getAnnouncementList`,
  // // --- Notifications---//
  // notification: `${baseURL}/${version_v1}/notification/getNotification`,
  // del: `${baseURL}/${version_v1}/notification/deleteNotification`,
  // // ---Add Bank ----//
  // bankStatus: `${baseURL}/${bank}/viewBank`,
  // addBank: `${baseURL}/${bank}/addBank`,
  // bankList: `${baseURL}/${version}/banking/get-bank`,
  // editBank: `${baseURL}/${bank}/editBank`,
  // deleteBank: `${baseURL}/${bank}/deleteBank`,
  // //----inr-deposit-withdraw----//
  // getAdminBank: `${baseURL}/${bank}/getAdminBank`,
  // uploadImageFile: `${baseURL}/${user}/uploadImageFile`,
  // depositRequest: `${baseURL}/${bank}/depositRequest`,
  // withdrawRequest: `${baseURL}/${bank}/withdrawRequest`,
  // //----locked balances----//
  // getLockFundList: `${baseURL}/${lockfund}/getLockFundList`,
  // viewLockFund: `${baseURL}/${lockfund}/viewLockFund`,
  // getLockWalletList: `${baseURL}/${lockfund}/getLockWalletList`,
  // //----user referral----//
  // getReferralRewards: `${baseURL}/${user}/getReferralRewards`,
  // deleteAccount: `${baseURL}/${user}/deleteAccount`,
  // //----reward----//
  // getRewardScratch: `${baseURL}/${reward}/getRewardScratch`,
  // scratchCard: `${baseURL}/${reward}/scratchCard`,
  // getRewardHistory: `${baseURL}/${reward}/getRewardHistory`,
  // //----help and support----//
  // categoryList: `${baseURL}/${help}/categoryList`,
  // raiseTicket: `${baseURL}/${help}/raiseTicket`,
  // queryTicketList: `${baseURL}/${help}/queryTicketList`,
  // ticketDetails: `${baseURL}/${help}/ticketDetails`,
  // reply: `${baseURL}/${help}/reply`,
  // queryRaise: `${baseURL}/${help}/queryRaise`,
  // submitFeedback: `${baseURL}/${help}/submitFeedback`,
  // //----notifiaction----//
  // readNotification: `${baseURL}/${notification}/readNotification`,
  // notification: `${baseURL}/${notification}/notification`,
  // //----payment options----//
  // moduleList: `${baseURL}/${user}/moduleList`,
  // paymentCoinList: `${baseURL}/${user}/paymentCoinList`,
  // //----orderbook-----//
  // accounts: `${orderbookJavaUrl}/${version}/accounts`,
  // setAccessToken: `${orderbookJavaUrl}/${version}/users/setAccessToken`,
  // products: `${orderbookJavaUrl}/${version}/products`,
  // accessToken: `${orderbookJavaUrl}/${version}/users/accessToken`,
};

export default apiConfigs;

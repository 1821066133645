import { Box, Typography } from "@mui/material";
import React from "react";

export default function CommonHeading({ heading, textCenter, fontSize }) {
  return (
    <Box>
      <Typography
        style={{ fontSize: fontSize && fontSize }}
        textAlign={textCenter && textCenter}
        variant="h2"
      >
        {heading}
      </Typography>
    </Box>
  );
}

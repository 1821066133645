import { lazy } from "react";
import { Navigate } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/home")),
  },
  {
    exact: true,
    path: "/market-overview",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/market")),
  },
  {
    exact: true,
    path: "/watchlist",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/watchlist")),
  },
  {
    exact: true,
    path: "/portfolio",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/portfolio")),
  },

  //user-profile
  {
    exact: true,
    guard: true,
    path: "/user-profile",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/security",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/security")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/notification")),
  },
  {
    exact: true,
    guard: true,
    path: "/currency",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/currency")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-kyc-info",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kyc")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/paymentoption")),
  },
  {
    exact: true,
    guard: true,
    path: "/api-management",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/apiManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/trade-history",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade-history")),
  },

  {
    exact: true,
    path: "/signup",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/SingUp")),
  },
  {
    exact: true,
    path: "/login",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Login.jsx")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ForgetPassword.jsx")),
  },
  {
    exact: true,
    path: "/check-identity",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/CheckIdentity.jsx")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ResetPassword.jsx")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Verify")),
  },

  {
    exact: true,
    path: "*",
    component: lazy(() => import("../views/errors/NotFound")),
  },

  {
    component: () => <Navigate to="/404" />,
  },
];

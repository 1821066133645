import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FaAddressCard, FaGlobeAmericas } from "react-icons/fa";
import Collapse from "@mui/material/Collapse";

import { styled } from "@mui/system";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import { useLocation, useNavigate } from "react-router";
import NavItem from "../../DashboardLayout/NavBar/NavItem";
import RightOptions from "../../DashboardLayout/TopBar/RightOptions";
import { MdClear } from "react-icons/md";

import Logo from "../../../components/Logo";
import { AuthContext } from "../../../context/auth";
import Divider from "@mui/material/Divider";
import FlexButton from "../../../components/FlexButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
const MobileDrawer = styled(Drawer)(({ theme }) => ({
  width: 272,
  "& .MuiPaper-root": {
    borderRadius: "5px",
  },
}));
const SideMenuBox = styled(Box)(({ theme }) => ({
  width: "200px",
  "& .MuiCollapse-wrapperInner": {
    marginLeft: "20px",
  },
}));

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: true,
    // });
    const open = pathname === item.href;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const sections = [
  {
    items: [
      {
        title: "Market",
        href: "/market-overview",
        icon: <ElectricBoltIcon />,
      },
      {
        title: "Watchlist",
        href: "/watchlist",
        icon: <BookmarkBorderIcon />,
      },
      {
        title: "Portfolio",
        href: "/portfolio",
        icon: <WorkOutlineIcon />,
      },
    ],
  },
];
const authenticatedSections = [
  {
    items: [
      ...sections[0].items,
      {
        title: "Trade History",
        href: "/trade-history",
        icon: <ElectricBoltIcon />,
      },
      {
        title: "Wallet",
        href: "/wallet",
        icon: <AccountBalanceWalletIcon />,
      },
    ],
  },
];

const currencyAndSymbol = [
  { initials: "USD", symbol: "$" },
  { initials: "EUR", symbol: "€" },
  { initials: "GBP", symbol: "£" },
  { initials: "JPY", symbol: "¥" },
  { initials: "AUD", symbol: "A$" },
];

const NavBarRight = ({ onMobileClose, openMobile }) => {
  const { userLoggedIn } = useContext(AuthContext);
  const route = useLocation();
  const themeSettings = useContext(ThemeContext);
  const navigate = useNavigate();
  const navArrayToUse = userLoggedIn ? authenticatedSections : sections;
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  // const { selectedCurrency, coinList } = useContext(FlexLayerContext);
  // const pair =
  //   coinList &&
  //   coinList[0] &&
  //   selectedCurrency &&
  //   `${coinList[0]?.symbol}/${selectedCurrency?.toUpperCase()}`;
  // console.log("pair----", pair);
  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK"
        ? colors.darkSecondary
        : "#1d1d1d0a",
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
  };
  // const content = (
  //   <Box
  //     height="100%"
  //     display="flex"
  //     flexDirection="column"
  //     justifyContent="center"
  //     sx={themeColors}
  //   >
  //     <Box pt={2} pb={2}>
  //       <SideMenuBox>
  //         {sections?.map((section, i) => (
  //           <List
  //             key={`menu${i}`}
  //             subheader={
  //               <ListSubheader disableGutters disableSticky>
  //                 {section.subheader}
  //               </ListSubheader>
  //             }
  //           >
  //             {renderNavItems({
  //               img: section.img,
  //               items: section.items,
  //               pathname: route.pathname,
  //               state: section.tabview,
  //             })}
  //           </List>
  //         ))}
  //       </SideMenuBox>
  //       <RightOptions
  //         themeSettings={themeSettings}
  //         sx={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       />
  //     </Box>

  //     {/* Rest of your code */}
  //   </Box>
  // );

  const navContent = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={themeColors}
    >
      <List
        sx={{ width: "95vw" }}
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{ backgroundColor: colors.bgDark, p: 2 }}
          >
            <Grid container item xs={12}>
              <Grid container xs={11}>
                <Logo />
              </Grid>
              <Grid container xs={1}>
                <IconButton onClick={onMobileClose}>
                  <MdClear />
                </IconButton>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        <Box
          sx={{
            backgroundColor: colors.bgDark,
            mt: 4,
          }}
        >
          {userLoggedIn ? (
            <>
              <ListItemButton sx={{ p: 2 }}>
                <ListItemIcon
                  sx={{
                    "&.MuiListItemIcon-root": {
                      minWidth: "32px",
                    },
                  }}
                >
                  <Tooltip title="Profile">
                    <IconButton
                      style={{
                        overflow: "hidden",
                        height: "64px",
                        width: "64px",
                      }}
                      onClick={() => navigate("/user-profile")}
                    >
                      <Avatar src="/images/banner/placeholder.png" />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box>
                      <Typography variant="h6">Allie Grater</Typography>
                      <Typography variant="subtitle2" color="#6c7080">
                        AllieGrater12345644@
                      </Typography>
                    </Box>
                  }
                />
              </ListItemButton>
              <Divider variant="middle" sx={{ background: "#6c7080" }} />
            </>
          ) : (
            <></>
          )}

          {navArrayToUse[0].items?.map((section, i) => (
            <ListItemButton
              sx={{ p: 2 }}
              onClick={async () => {
                await onMobileClose();
                navigate(section.href);
              }}
            >
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "32px",
                  },
                }}
              >
                {/* <AiOutlineThunderbolt size={"20px"} /> */}
                {section.icon}
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="h6">{section.title}</Typography>}
              />
            </ListItemButton>
          ))}

          <ListItemButton sx={{ p: 2 }} onClick={handleClick}>
            <ListItemIcon
              sx={{
                "&.MuiListItemIcon-root": {
                  minWidth: "32px",
                },
              }}
            >
              <FaGlobeAmericas size={"20px"} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="h6">English | USD</Typography>}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ padding: 1 }}>
            <Grid
              container
              item
              xs={12}
              sx={{
                backgroundColor: "#080808",
              }}
            >
              <Grid container item xs={12}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "#5367ff",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#5367ff",
                    },
                  }}
                >
                  <Tab label="Language" />
                  <Tab label="Currency" />
                </Tabs>
              </Grid>
              <Grid container item xs={12}>
                <List
                  sx={{
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {currencyAndSymbol?.map((item, idx) => (
                    <ListItemButton sx={{ width: "100%" }}>
                      <ListItemText
                        primary={
                          <Typography variant="caption">{`${item.initials} - ${item.symbol}`}</Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Collapse>

          {userLoggedIn ? (
            <></>
          ) : (
            <>
              <Divider
                variant="middle"
                sx={{ my: 3, backgroundColor: "#323338" }}
              />
              <Grid
                container
                item
                xs={12}
                gap={1}
                justifyContent={"center"}
                pb={2}
              >
                <Grid
                  item
                  xs={5}
                  container
                  sx={{ justifyContent: { xs: "center", sm: "flex-end" } }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#e9eaf0",
                      color: "#5367ff",
                      maxWidth: "200px",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Sign in
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={5}
                  container
                  sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#5367ff",
                      color: "#ffffffs",
                      maxWidth: "200px",
                    }}
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </List>
    </Box>
  );

  // {sections?.map((section, i) => (
  //   <List
  //     key={`menu${i}`}
  //     subheader={
  //       <ListSubheader disableGutters disableSticky>
  //         {section.subheader}
  //       </ListSubheader>
  //     }
  //   >
  //     {renderNavItems({
  //       img: section.img,
  //       items: section.items,
  //       pathname: route.pathname,
  //       state: section.tabview,
  //     })}
  //   </List>
  // ))}

  return (
    <Hidden lgUp>
      <MobileDrawer
        anchor="right"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {/* <Box p={2}>{content}</Box> */}
        <Box p={2}>{navContent}</Box>
      </MobileDrawer>
    </Hidden>
  );
};

NavBarRight.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBarRight;

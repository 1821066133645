import React, { useState, useContext, useEffect } from "react";

import ThemeContext from "../../context/themeContext";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { IconButton } from "@mui/material";

export default function ThemeSwitchButton() {
  const themeSettings = useContext(ThemeContext);
  const [isDark, setIsDark] = useState(false);
  const changeTheme = (type) => {
    themeSettings.saveSettings({
      theme: type,
    });
  };
  useEffect(() => {
    if (themeSettings.settings.theme === "DARK") {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [themeSettings.settings.theme]);

  return (
    <IconButton
      onClick={() => {
        changeTheme(
          themeSettings.settings.theme === "LIGHT" ? "DARK" : "LIGHT"
        );
        setIsDark(themeSettings.settings.theme === "LIGHT" ? true : false);
      }}
    >
      {!isDark ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
}

import { ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { createAppTheme } from "./theme";
import ThemeContext from "./context/themeContext";
import { routes } from "./routes";
import { RenderRoutes } from "./routes/renderRoutes";
import "react-phone-input-2/lib/style.css";
import { Toaster } from "react-hot-toast";
import "animate.css/animate.min.css";
import CookieConsent from "react-cookie-consent";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  const themeSettings = useContext(ThemeContext);
  const theme = createAppTheme({
    theme: themeSettings.settings.theme,
  });

  // useEffect(() => {
  //   const script1 = document.createElement("script");
  //   script1.src = "https://ind-widget.freshworks.com/widgets/1070000000939.js";
  //   document.head.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   script2.src = "https://ind-widget.freshworks.com/widgets/1070000000937.js";
  //   document.head.appendChild(script2);

  //   script1.onload = script2.onload = () => {
  //     // Initialize FreshworksWidget here
  //   };

  //   // Clean up scripts on unmount
  //   return () => {
  //     document.head.removeChild(script1);
  //     document.head.removeChild(script2);
  //   };
  // }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RenderRoutes data={routes} />
        <Toaster
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
          theme={theme.palette.type}
        />
        {/* <CookieConsent
    
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent> */}
      </ThemeProvider>
    </div>
  );
}

export default App;

import { Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/colors";

export default function ExtraSmallHeading({ heading, isWhiteSpace }) {
  return (
    <>
      <Typography
        color={colors.textColor}
        style={{ fontSize: "14px", whiteSpace: isWhiteSpace ? "pre" : "none" }}
      >
        {heading}
      </Typography>
    </>
  );
}

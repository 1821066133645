import axios from "axios";
import apiConfigs from "..";

// import CryptoJS from "crypto-js";

export const apiRouterCall = async ({
  method,
  id,
  endPoint,
  data,
  params,
  token,
  source,
  url,
}) => {
  try {
    return axios({
      method: method,
      url: url
        ? url
        : id
        ? `${apiConfigs[endPoint]}/${id}`
        : apiConfigs[endPoint],
      headers: {
        token: token ? token : window.sessionStorage.getItem("access_token"),
      },
      data: data ? data : null,
      // data: data
      //   ? CryptoJS.AES.encrypt(
      //       JSON.stringify(data),
      //       "secret key 123"
      //     ).toString()
      //   : null,
      params: params ? params : null,
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error, "error");
    return error.response;
  }
};
